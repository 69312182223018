@import-normalize;
@import 'reset';
@import './fonts/fonts';
@import './variables/base';
@import './variables/variables';
@import 'variables/global';

.app {
	min-height: 100vh;
}

.content-page {
	display: flex;
}

.page-wrapper {
	flex-grow: 1;
	padding: 20px;
}
