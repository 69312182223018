@use 'app/styles/app' as *;

.wrapper {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	background-color: transparent;
	height: 100vh;
	width: 100vw;
}
