* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

input,
button,
textarea,
select {
	margin: 0;
	font: inherit;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

button {
	cursor: pointer;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}