@use './variables' as *;

*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	font-family: $main-font;
	font-style: normal;
	margin: 0;
	color: $primary-text-color;
	background-color: black;
	transition: 0.3s ease-in-out;
}

html {
	scroll-behavior: smooth;
}

svg {
	display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

button {
	cursor: pointer;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.active {
	color: $secondary-text-color;
}

.splide__pagination {
	bottom: -2.5em !important;
}
